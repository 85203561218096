<template>
  <div class="lg:text-xl robotic-surgery home relative text-base">
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeader />
    <div class="spacer lg:pt-0 pt-8"></div>
    <section class="lg:pt-24 lg:bg-cover bg-sub-banner relative pt-12 overflow-hidden text-gray-800 bg-center">
      <div
        class="md:w-1/3 lg:w-1/2 bg-surgery lg:opacity-75 absolute top-0 right-0 w-1/2 h-full bg-left bg-cover opacity-50">
      </div>
      <div class="max-w-screen-xl px-4 py-16 mx-auto">
        <h2 class="lg:font-thin lg:text-6xl text-4xl font-light">Minimally Invasive Surgery</h2>
        <p class="lg:text-base text-sm">Home > <span class="text-sitePurple font-bold">Minimally Invasive Surgery</span>
        </p>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl pt-12 pb-8 mx-auto">
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="w-full px-4 overflow-hidden">
            <p class="mb-4">
              With decades of experience in plastic surgery, Dr Marco is always on the lookout for safer, newer and
              modern medical advancements to help patients achieve optimum results.
            </p>
            <p class="mb-8">
              Dr Marco was the initial plastic surgeon who identified that endoscopic techniques can be used for plastic
              surgery, and proved its success with faster healing, reduced scarring and safer results. As a result of
              this feat, he was awarded by 6 different societies in this process, eventually becoming an authority in
              this field.
            </p>
            <a href="tel:+6564648075" class="lg:text-xl text-sitePurple inline-block w-full mb-4 text-lg text-left">Find
              out more about minimally invasive plastic surgery by making an appointment today. Call Dr Marco at +65
              6464 8075 now.</a>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800 bg-gray-200">
      <div class="lg:px-24">
        <div class="lg:px-4 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-service-03.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold uppercase">Da Vinci Robotic
                  Surgery</h4>
                <p class="lg:text-xl mb-4 text-lg font-bold">Dr Marco uses the da Vinci surgical system and has the
                  following accreditation:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Certificate Diploma in Robotic Surgery awarded by EACCME, Nancy, France</li>
                  <li class="mb-1">Certificate of da Vinci System Training as a console surgeon, Korea Yonsei – Shinchon
                    Severance</li>
                  <li>Certificate of da Vinci Surgical System Training module completion</li>
                </ul>
                <p class="mb-4">
                  The da Vinci surgical system allows Dr Marco to visualize a 3D high-resolution surgical field and
                  provides him with control over robotic arms that can twist, bend and operate with a greater range than
                  with human hands.
                </p>
                <p class="mb-4">
                  As such, Dr Marco performs minimally invasive surgery that is both safe and precise. It is important
                  to note that this is still Dr Marco operating, with him using the da Vinci surgical system for
                  increased accuracy.
                </p>
                <p class="mb-4">
                  Some advantages of robotic surgery include:
                </p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Minimal tissue trauma</li>
                  <li class="mb-1">Lower rate of infection</li>
                  <li class="mb-1">Quicker recovery</li>
                  <li>Smaller healing scars of 0.5cm to 1.5cm</li>
                </ul>
                <p class="mb-4">
                  Robotic surgery has also seen use in other medical fields such as cardiac, colorectal, general
                  surgery, gynaecology, urology, head & neck surgery.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-service-04.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold uppercase">Endoscopic Surgery in
                  Aesthetics</h4>
                <p class="lg:text-xl mb-4 text-lg font-bold">As part of his journey to provide a positive patient
                  experience, Dr Marco uses endoscopic surgery to perform key aesthetic procedures such as:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Abdominoplasty/tummy tuck</li>
                  <li class="mb-1">Browlifts</li>
                  <li class="mb-1">Forehead lifts</li>
                  <li class="mb-1">Breast augmentation</li>
                  <li class="mb-1">Nasal surgery</li>
                  <li>Rectus diastasis repair</li>
                </ul>
                <p class="mb-4">
                  In the hands of an experienced doctor like Dr Marco, endoscopic surgery can give you stellar results
                  with minimal discomfort. Rest assure that Dr Marco will assist you with all your queries, needs and
                  concerns in a thorough manner before commencing on any procedure.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SiteDoctor />
    <SiteConsultation />
    <SiteForm />
    <SiteFooter />
  </div>
</template>

<script>
  import SiteHeader from '@/components/SiteHeader.vue'
  import SiteDoctor from '@/components/SiteDoctor.vue'
  import SiteConsultation from '@/components/SiteConsultation.vue'
  import SiteForm from '@/components/SiteForm.vue'
  import SiteFooter from '@/components/SiteFooter.vue'
  export default {
    components: {
      SiteHeader,
      SiteDoctor,
      SiteConsultation,
      SiteForm,
      SiteFooter
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>

<style>
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
  .bg-surgery {
    background-image: url(/images/bg-surgery.png);
  }
</style>